.certificate-verifier {
  max-width: 800px; /* Increase from 600px */
  width: 100%;
  margin: 2rem auto;
  padding: 2rem;
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.certificate-verifier h2 {
  margin-bottom: 1.5rem;
  color: var(--primary-color);
}

.input-group {
  display: flex;
  margin-bottom: 1.5rem;
}

.input-group input {
  flex: 1;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 5px 0 0 5px;
  font-size: 1rem;
}

.input-group button {
  padding: 0.75rem 1.5rem;
  background-color: var(--primary-color);
  color: var(--white);
  border: none;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.input-group button:hover {
  background-color: var(--secondary-color);
}

.result {
  padding: 1.5rem;
  border-radius: 5px;
  margin-top: 1.5rem;
}

.result.valid {
  background-color: #e7f3e8;
  border: 1px solid #c3e6cb;
}

.result.invalid {
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
}

.result h3 {
  margin-bottom: 1rem;
}

.result p {
  margin-bottom: 0.5rem;
}

.btn-view {
  display: inline-block;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: var(--primary-color);
  color: var(--white);
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.btn-view:hover {
  background-color: var(--secondary-color);
}

@media (max-width: 850px) {
  .certificate-verifier {
    max-width: 90%;
    padding: 1.5rem;
  }
}
