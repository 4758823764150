.dashboard {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.no-api-keys {
  text-align: center;
  margin-top: 2rem;
}

.create-api-key-button {
  display: inline-block;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.create-api-key-button:hover {
  background-color: #0056b3;
}

.error-message {
  background-color: #ffebee;
  color: #c62828;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.loading-message {
  color: #2196f3;
  font-weight: bold;
}

.api-keys {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
}

.api-key-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 1.5rem;
  transition: box-shadow 0.3s ease;
}

.api-key-card:hover {
  box-shadow: 0 4px 8px rgba(0,0,0,0.15);
}

.api-key-card h3 {
  color: #1976d2;
  margin-top: 0;
}

.api-key-card p {
  margin: 0.5rem 0;
}

.api-key-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.copy-button, .delete-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.copy-button {
  background-color: #4caf50;
  color: white;
}

.copy-button:hover {
  background-color: #45a049;
}

.delete-button {
  background-color: #f44336;
  color: white;
}

.delete-button:hover {
  background-color: #d32f2f;
}

.notification {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #333;
  color: white;
  padding: 1rem;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}
