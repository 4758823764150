@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');



.auth-container {
  max-width: 800px; /* Increase from previous value, e.g., 400px */
  width: 360px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.auth-container:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.15);
}

.auth-container h1 {
  text-align: center;
  color: #333;
  margin-bottom: 30px;
  font-weight: 600;
}

.auth-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group1 {
  position: relative;
}

.form-group1 label {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #888;
  pointer-events: none;
  transition: all 0.3s ease;
}

.form-group1 input {
  width: 100%;
  padding: 15px;
  border: 2px solid #e0e0e0;
  border-radius: 10px;
  font-size: 16px;
  transition: all 0.3s ease;
}

.form-group1 input:focus {
  border-color: #6e8efb;
  box-shadow: 0 0 0 3px rgba(110, 142, 251, 0.1);
}

.form-group1 input:focus + label,
.form-group1 input:not(:placeholder-shown) + label {
  top: 0;
  font-size: 12px;
  background-color: #fff;
  padding: 0 5px;
}

.auth-button {
  background-color: #6e8efb;
  color: #fff;
  border: none;
  border-radius: 10px;
  padding: 15px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.auth-button:hover {
  background-color: #5a7af0;
}

.error-message {
  color: #ff4757;
  background-color: #ffe0e3;
  border: 1px solid #ff4757;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  text-align: center;
}

.success-message {
  color: #28a745;
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 15px;
  text-align: center;
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.success-message.show {
  opacity: 1;
  transform: translateY(0);
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.auth-container.success {
  animation: fadeInUp 0.5s ease forwards;
}

.auth-links {
  margin-top: 20px;
  text-align: center;
}

.auth-link {
  margin: 10px 0;
  font-size: 14px;
  color: #666;
}

.auth-link a {
  color: #6e8efb;
  text-decoration: none;
  transition: color 0.3s ease;
}

.auth-link a:hover {
  color: #5a7af0;
}

/* Add this to your existing Auth.css file */
.link-button {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  color: #6e8efb;
  text-decoration: underline;
}

.link-button:hover {
  color: #ffffff;
}

@media (max-width: 850px) {
  .auth-container {
    max-width: 90%;
    padding: 1.5rem;
  }
}