.bulk-certificate-generator {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .bulk-certificate-generator h1 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .bulk-certificate-generator form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  .form-group label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .form-group input,
  .form-group select,
  .form-group textarea {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .logo-uploader,
  .csv-uploader {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .signature-container {
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 4px;
    background-color: #fff;
  }
  
  .signature-entry {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #eee;
  }
  
  .signature-canvas {
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .processing-indicator {
    text-align: center;
    margin-top: 20px;
  }
  
  .loading-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  
  .dot1, .dot2, .dot3 {
    width: 10px;
    height: 10px;
    background-color: #007bff;
    border-radius: 50%;
    animation: bounce 1.4s infinite ease-in-out both;
  }
  
  .dot1 {
    animation-delay: -0.32s;
  }
  
  .dot2 {
    animation-delay: -0.16s;
  }
  
  @keyframes bounce {
    0%, 80%, 100% { 
      transform: scale(0);
    } 40% { 
      transform: scale(1.0);
    }
  }
  
  .error-alert {
    background-color: #f8d7da;
    color: #721c24;
    padding: 10px;
    border-radius: 4px;
    margin-top: 15px;
    text-align: center;
  }