/* src/components/TemplateEditor.css */
.template-editor {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 120px);
    overflow: hidden;
    background-color: #f8f8f8;
  }
  
  .editor-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    background-color: #ffffff;
    border-bottom: 1px solid #ddd;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .template-name-input {
    font-size: 18px;
    font-weight: 500;
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 300px;
  }
  
  .editor-controls button {
    margin-left: 10px;
    padding: 8px 16px;
    background-color: #4a90e2;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s;
  }
  
  .editor-controls button:hover {
    background-color: #3a80d2;
  }
  
  .editor-workspace {
    display: flex;
    flex: 1;
    overflow: hidden;
  }
  
  .editor-toolbar {
    width: 300px;
    padding: 20px;
    background-color: #ffffff;
    border-right: 1px solid #ddd;
    overflow-y: auto;
  }
  
  .toolbar-section {
    margin-bottom: 25px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
  }
  
  .toolbar-section:last-child {
    border-bottom: none;
  }
  
  .toolbar-section h3 {
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 16px;
    color: #333;
    font-weight: 600;
  }
  
  .toolbar-section button {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px 15px;
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 4px;
    text-align: left;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .toolbar-section button:hover {
    background-color: #e9e9e9;
  }
  
  .property-group {
    margin-bottom: 15px;
  }
  
  .property-group label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
    color: #555;
  }
  
  .property-group input,
  .property-group select,
  .property-group textarea {
    width: 100%;
    padding: 8px 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .property-group textarea {
    min-height: 80px;
    resize: vertical;
  }
  
  .delete-button {
    margin-top: 20px;
    background-color: #f8d7da !important;
    color: #721c24 !important;
    border: 1px solid #f5c6cb !important;
  }
  
  .delete-button:hover {
    background-color: #f1b0b7 !important;
  }
  
  .canvas-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e9e9e9;
    overflow: auto;
    padding: 30px;
  }
  
  .canvas-container canvas {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  .preview-controls {
    padding: 20px;
    background-color: #ffffff;
    border-top: 1px solid #ddd;
  }
  
  .preview-controls h3 {
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 16px;
    color: #333;
  }
  
  .sample-data-form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
    color: #555;
  }
  
  .form-group input {
    width: 100%;
    padding: 8px 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
  }