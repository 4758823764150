/* src/components/TemplateManagement.css */
.template-management {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .management-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  }
  
  .management-header h2 {
    margin: 0;
    color: #333;
  }
  
  .create-button {
    display: inline-block;
    padding: 0.75rem 1.5rem;
    background-color: #4a90e2;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    font-weight: 500;
    transition: background-color 0.3s ease;
  }
  
  .create-button:hover {
    background-color: #3a80d2;
  }
  
  .no-templates {
    text-align: center;
    padding: 3rem;
    background-color: #f8f8f8;
    border-radius: 8px;
  }
  
  .no-templates p {
    margin-bottom: 1.5rem;
    color: #666;
    font-size: 1.1rem;
  }
  
  .template-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 2rem;
  }
  
  .template-card {
    border: 1px solid #eee;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .template-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  .template-preview {
    height: 180px;
    overflow: hidden;
    background-color: #f5f5f5;
  }
  
  .template-thumbnail {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666;
    font-size: 1.2rem;
  }
  
  .template-info {
    padding: 1rem;
    border-bottom: 1px solid #eee;
  }
  
  .template-info h3 {
    margin: 0 0 0.5rem 0;
    font-size: 1.2rem;
    color: #333;
  }
  
  .template-info p {
    margin: 0;
    color: #888;
    font-size: 0.9rem;
  }
  
  .template-actions {
    display: flex;
    padding: 1rem;
  }
  
  .template-actions a,
  .template-actions button {
    flex: 1;
    padding: 0.5rem;
    text-align: center;
    border-radius: 4px;
    margin: 0 0.25rem;
    font-size: 0.9rem;
    cursor: pointer;
  }
  
  .edit-button {
    background-color: #f8f8f8;
    color: #333;
    text-decoration: none;
    border: 1px solid #ddd;
  }
  
  .use-button {
    background-color: #4a90e2;
    color: white;
    text-decoration: none;
    border: 1px solid #4a90e2;
  }
  
  .delete-button {
    background-color: #fff;
    color: #e74c3c;
    border: 1px solid #e74c3c;
  }
  
  .edit-button:hover {
    background-color: #eee;
  }
  
  .use-button:hover {
    background-color: #3a80d2;
  }
  
  .delete-button:hover {
    background-color: #e74c3c;
    color: white;
  }
  
  .loading,
  .error {
    text-align: center;
    padding: 3rem;
    color: #666;
  }
  
  .error {
    color: #e74c3c;
  }