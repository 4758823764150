.certificate-generator {
  max-width: 800px; /* Increase from 800px */
  width: 100%;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  color: #343a40;
  margin-bottom: 2rem;
}

form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

label {
  font-weight: bold;
  color: #495057;
}

input[type="text"],
input[type="date"],
select,
textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 5px;
  font-size: 1rem;
  transition: border-color 0.2s ease-in-out;
}

input[type="text"]:focus,
input[type="date"]:focus,
select:focus,
textarea:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

textarea {
  resize: vertical;
  min-height: 100px;
}

input[type="file"] {
  padding: 0.5rem 0;
}

.signature-options {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.signature-options label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.signature-pad-container,
.signature-upload-container {
  border: 1px solid #ced4da;
  border-radius: 5px;
  overflow: hidden;
}

.signature-canvas {
  width: 100%;
  height: 200px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.signature-field {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.signature-field select {
  width: 200px;
}

button{
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  align-self: flex-start;
}
button:hover{
  background-color: #0056b3;
}

button[type="submit"] {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  align-self: flex-start;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}

@media (max-width: 1050px) {
  .certificate-generator {
    max-width: 90%;
    padding: 1.5rem;
  }

  .signature-canvas {
    height: 150px;
  }
}

/* Add these styles to your existing CSS */

.error-message {
  color: #dc3545;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
  padding: 0.75rem;
  margin-bottom: 1rem;
}

button[type="submit"]:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.success-message {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
  border-radius: 5px;
  padding: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 1; 
  visibility: visible; 
}

.success-message p {
  margin-bottom: 0.5rem;
  color: #155724; 
}

.success-message button {
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  opacity: 1; 
}

.success-message button:hover {
  background-color: #218838;
}

.success-message.show {
  display: flex;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.template-selection {
  margin-bottom: 2rem;
}

.template-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
}

.template-item {
  border: 2px solid #ddd;
  border-radius: 8px;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.template-item:hover,
.template-item.selected {
  border-color: var(--primary-color);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.template-item img {
  width: 100%;
  height: auto;
  border-radius: 4px;
}

.template-item p {
  margin-top: 0.5rem;
  text-align: center;
}
