.contact-us {
    max-width: 600px;
    margin: 0 auto;
    padding: 2rem;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .contact-us h1 {
    color: #343a40;
    margin-bottom: 1rem;
  }
  
  .contact-us p {
    margin-bottom: 2rem;
  }
  
  .contact-us a {
    color: #007bff;
    text-decoration: none;
  }
  
  .contact-us a:hover {
    text-decoration: underline;
  }
  
  .contact-us form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .form-group label {
    font-weight: bold;
    color: #495057;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ced4da;
    border-radius: 5px;
    font-size: 1rem;
    transition: border-color 0.2s ease-in-out;
  }
  
  .form-group input:focus,
  .form-group textarea:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }
  
  .form-group textarea {
    resize: vertical;
    min-height: 150px;
  }
  
  button[type="submit"] {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  button[type="submit"]:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 768px) {
    .contact-us {
      padding: 1.5rem;
    }
  }