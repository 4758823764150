@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

:root {
  --primary-color: #6e8efb;
  --secondary-color: #a777e3;
  --text-color: #333;
  --bg-color: #f5f7fa;
  --white: #ffffff;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: var(--bg-color);
  color: var(--text-color);
  line-height: 1.6;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.navbar {
  background-color: var(--white);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.navbar-logo {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--primary-color);
  text-decoration: none;
}

.navbar-menu {
  display: flex;
  list-style-type: none;
  align-items: center;
}

.navbar-menu li {
  margin-left: 1.5rem;
}

.navbar-menu a {
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

.navbar-menu a:hover,
.navbar-menu a.active {
  color: var(--text-color);
}

.btn {
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-weight: 600;
  transition: all 0.3s ease;
}

.btn-login {
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
}

.btn-signup {
  background-color: var(--primary-color);
  color: var(--white);
  transition: color 0.3s ease;
}

.btn-logout {
  color: var(--secondary-color);
  border: 2px solid var(--secondary-color);
}

.navbar-toggle {
  display: none;
}

.main-content {
  flex: 1;
  padding: 2rem;
}

@media (max-width: 768px) {
  .navbar-toggle {
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }

  .navbar-toggle span {
    height: 3px;
    width: 25px;
    background-color: var(--text-color);
    margin-bottom: 4px;
    border-radius: 2px;
    transition: all 0.3s ease;
  }

  .navbar-menu {
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    height: calc(100vh - 60px);
    background-color: var(--white);
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    padding: 2rem;
  }

  .navbar-menu.open {
    transform: translateX(0);
  }

  .navbar-menu li {
    margin: 1rem 0;
  }

  .btn {
    width: 100%;
    text-align: center;
    margin-top: 1rem;
  }
}

.account-menu-container {
  position: relative;
}

.account-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.user-icon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.account-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: var(--white);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  list-style-type: none;
  padding: 0.5rem 0;
  min-width: 150px;
  z-index: 1000;
}

.account-dropdown li {
  margin: 0;
}

.account-dropdown a {
  display: block;
  padding: 0.5rem 1rem;
  color: var(--text-color);
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.account-dropdown a:hover {
  background-color: var(--bg-color);
}

@media (max-width: 768px) {
  .account-menu-container {
    width: 100%;
    text-align: center;
    margin-top: 1rem;
  }

  .account-dropdown {
    position: static;
    width: 100%;
    box-shadow: none;
    background-color: transparent;
  }

  .account-dropdown a {
    padding: 1rem;
  }
}

.menu-container {
  position: relative;
}

.menu-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.user-icon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.menu-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: var(--white);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  list-style-type: none;
  padding: 1rem;
  min-width: 250px;
  max-height: calc(100vh - 80px);
  overflow-y: auto;
  z-index: 1000;
}

.menu-dropdown li {
  margin: 0;
}

.menu-dropdown a {
  display: block;
  padding: 0.75rem 1rem;
  color: var(--text-color);
  text-decoration: none;
  transition: background-color 0.3s ease;
  border-radius: 5px;
}

.menu-dropdown a:hover {
  background-color: var(--bg-color);
}

.menu-section {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--bg-color);
}

.menu-section:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.menu-section-title {
  font-size: 0.9rem;
  font-weight: 600;
  color: var(--secondary-color);
  margin-bottom: 0.5rem;
  padding-left: 1rem;
}

@media (max-width: 768px) {
  .menu-dropdown {
    position: absolute;
    top: 60px;
    right: 10px;
    width: calc(100% - 20px);
    max-width: 300px;
  }
}

.btn-ai-generate{
  animation: aiTextAnimation 3s infinite;
}

@keyframes aiTextAnimation {
  0% {
    color: var(--primary-color);
  }
  25% {
    color: #ff8c00; /* Dark Orange */
  }
  50% {
    color: var(--secondary-color);
  }
  75% {
    color: #32cd32; /* Lime Green */
  }
  100% {
    color: var(--primary-color);
}
}

.app-footer {
  background-color: #2c3e50;
  color: #ecf0f1;
  padding: 40px 0 20px;
  margin-top: 50px;
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-section {
  flex: 1;
  min-width: 250px;
  margin-bottom: 30px;
  padding-right: 20px;
}

.footer-section h3 {
  font-size: 18px;
  margin-bottom: 15px;
  color: #3498db;
  position: relative;
  padding-bottom: 10px;
}

.footer-section h3::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50px;
  height: 2px;
  background-color: #3498db;
}

.footer-section p {
  margin-bottom: 10px;
  line-height: 1.6;
  color: #bdc3c7;
}

.footer-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-section ul li {
  margin-bottom: 10px;
}

.footer-section ul li a {
  color: #bdc3c7;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-section ul li a:hover {
  color: #3498db;
}

.footer-bottom {
  text-align: center;
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #34495e;
}

.footer-bottom p {
  font-size: 14px;
  color: #95a5a6;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
  }
  
  .footer-section {
    margin-bottom: 30px;
    padding-right: 0;
  }
}