@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css');

.home {
  font-family: 'Poppins', sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.hero {
  text-align: center;
  padding: 4rem 2rem;
  background: linear-gradient(135deg, #6e8efb, #a777e3);
  border-radius: 1rem;
  color: white;
  margin-bottom: 4rem;
  overflow: hidden;
  position: relative;
}

.hero::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255,255,255,0.1) 10%, transparent 10%);
  background-size: 20px 20px;
  transform: rotate(30deg);
  z-index: 1;
}

.title {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  line-height: 1.2;
  position: relative;
  z-index: 2;
}

.title-line {
  display: block;
  animation: slideInLeft 0.8s ease-out both;
}

.title-line:nth-child(2) {
  animation-delay: 0.2s;
}

.subtitle {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  animation: fadeInUp 0.8s ease-out 0.4s both;
  position: relative;
  z-index: 2;
}

.cta-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  animation: fadeInUp 0.8s ease-out 0.6s both;
  position: relative;
  z-index: 2;
}

.cta-button {
  padding: 0.8rem 1.5rem;
  border-radius: 2rem;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
}

.cta-button.primary {
  background-color: #ffffff;
  color: #6e8efb;
}

.cta-button.primary:hover {
  background-color: #f0f0f0;
  transform: translateY(-3px);
}

.cta-button.secondary {
  background-color: transparent;
  color: #ffffff;
  border: 2px solid #ffffff;
}

.cta-button.secondary:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transform: translateY(-3px);
}

.features {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  margin-top: 4rem;
}

.feature {
  flex: 1;
  text-align: center;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.feature:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.feature i {
  font-size: 3rem;
  color: #6e8efb;
  margin-bottom: 1rem;
}

.feature h2 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #333;
}

.feature p {
  color: #666;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@media (max-width: 768px) {
  .hero {
    padding: 3rem 1rem;
  }

  .title {
    font-size: 2.5rem;
  }

  .subtitle {
    font-size: 1rem;
  }

  .features {
    flex-direction: column;
  }

  .cta-buttons {
    flex-direction: column;
    align-items: center;
  }

  .cta-button {
    width: 100%;
    max-width: 250px;
    margin-bottom: 1rem;
  }
}