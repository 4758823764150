.api-key-generator {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  input[type="text"],
  input[type="email"],
  textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #45a049;
  }
  
  .error-message {
    color: red;
    margin-bottom: 10px;
  }
  
  .loading-message {
    color: #666;
    margin-bottom: 10px;
  }
  
  .success-message1 {
    background-color: #e7f3e8;
    border: 1px solid #c3e6cb;
    border-radius: 4px;
    padding: 15px;
    margin-top: 20px;
    color: #155724; /* Add this line to ensure text is visible */
  }
  
  .success-message1 p {
    margin-bottom: 10px; /* Add this block to improve spacing */
  }
  
  .api-key-actions {
    margin-top: 15px;
  }
  
  .api-key-actions button {
    margin-right: 10px;
  }
  
  .copy-success {
    color: #28a745; /* Add this block to style the copy success message */
    font-weight: bold;
  }