.settings {
    max-width: 600px;
    margin: 0 auto;
    padding: 2rem;
    background-color: var(--white);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .settings h1 {
    color: var(--primary-color);
    margin-bottom: 2rem;
    text-align: center;
  }
  
  .settings h2 {
    color: var(--secondary-color);
    margin-bottom: 1.5rem;
  }
  
  .settings form {
    background-color: var(--bg-color);
    padding: 1.5rem;
    border-radius: 8px;
  }
  
  .form-group {
    margin-bottom: 1.5rem;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
    color: var(--text-color);
  }
  
  .form-group input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.3s ease;
  }
  
  .form-group input:focus {
    outline: none;
    border-color: var(--primary-color);
  }
  
  .settings button {
    background-color: var(--primary-color);
    color: var(--white);
    border: none;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .settings button:hover {
    background-color: var(--secondary-color);
  }
  
  .message {
    margin-top: 1.5rem;
    padding: 1rem;
    border-radius: 4px;
    font-weight: 500;
  }
  
  .message.success {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
  }
  
  .message.error {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
  }
  
  @media (max-width: 768px) {
    .settings {
      padding: 1.5rem;
    }
  
    .settings form {
      padding: 1rem;
    }
  
    .form-group input {
      padding: 0.6rem;
    }
  
    .settings button {
      width: 100%;
    }
  }